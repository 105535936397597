

import {anchorLinkScroll, isInViewport, debounce} from './modules/helpers.js';

// Take some useful functions
import {
	closest_polyfill,
} from './modules/helpers.js';

// Tabs functionality (uncomment it if you need it)
import { tabsNavigation } from './modules/navi-tabs';



/**
 * All custom code is wrapped in IIFE function
 * to prevent affect our code to another parts of code
 */
;(function ( $ ) {

	/**
	 * PLease Collect here all variables with DOM elements
	 * Use const for all DOM elements and type it as UPPERCASE text
	 * It will help to each developer understand that it's a const not a variable
	 */
	const paginationLink = document.querySelectorAll('.js-company-pagination');
	const body = document.querySelector('body');

	body.addEventListener('click', event => {
		const role = event.target.dataset.role;

		switch (role) {
			case 'open-company': {
				event.preventDefault();

				const parent = event.target.closest('.js-company-item');
				parent.classList.add('active');
				const buttons =  parent.querySelectorAll('.js-open-company-btn');

				buttons && buttons.forEach(item => {
					item.classList.add('active');
				});

				parent && parent.querySelector('.js-company-item-wrap').classList.add('open');
			}
				break;
			case 'close-company' : {
				event.preventDefault();

				const parent = event.target.closest('.js-company-item');
				parent.classList.remove('active');
				const buttons =  parent.querySelectorAll('.js-open-company-btn');

				buttons && buttons.forEach(item => {
					item.classList.remove('active');
				});

				parent && parent.querySelector('.js-company-item-wrap').classList.remove('open');

			}
				break;
			case 'open-company-btn': {
				event.preventDefault();
				const parent = event.target.closest('.js-company-item');
				parent.classList.add('active');
				const wrapper = parent.querySelector('.js-company-item-wrap');

				if( !wrapper.classList.contains('open') ) {
					wrapper && wrapper.classList.add('open');
					event.target.classList.add('active');
				} else {
					const url = event.target.getAttribute('href');

					if(url) {
						window.open(url);
					}
				}
			}
				break;
		}

	})

	paginationLink && paginationLink.forEach(item => {
		item.addEventListener('click', event => {
			event.preventDefault();
			const companySection = event.target.closest('.js-company-section');
			const companyList = companySection.querySelector('.js-company-list');
			const companyPaginationWrap = companySection.querySelector('.js-company-pagination');
			if(!companyPaginationWrap || !companyList) return;
			const pageData = event.target.dataset.page;
			if(!pageData) return;
			const totalPage = companyPaginationWrap.dataset.total;
			const term = companyPaginationWrap.dataset.term;
			const buttonLearnText = companySection.dataset.learn;
			const buttonWebsiteText = companySection.dataset.website;
			const buttonSendText = companySection.dataset.send;

			const data = new FormData();
			data.append( 'action', 'company_pagination' );
			data.append( 'page', pageData);
			data.append( 'total_page', totalPage);
			data.append( 'term', term);
			data.append( 'learn_text', buttonLearnText);
			data.append( 'website_text', buttonWebsiteText);
			data.append( 'send_text', buttonSendText);

			fetch(var_from_php.ajax_url,
				{
					method: "POST",
					credentials: 'same-origin',
					body: data
				})
				.then((response) => response.json())
				.then((dataResponse) => {
					if(!dataResponse.success) return;
					const content = dataResponse.data.content;
					const listWrapper = companySection.querySelector('.js-company-list');
					const pagination = dataResponse.data.pagination;
					companyPaginationWrap.innerHTML = pagination;
					companyList.innerHTML = content;
					let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
					const companyFeedbacks = $(".company-feedbacks");

					if(screenWidth <= 768 ) {
						if(companyFeedbacks.length) {
							if(!companyFeedbacks.hasClass("slick-initialized")) {
								companyFeedbacks.slick({
									dots: true,
									infinite: true,
									speed: 500,
									slidesToShow: 1,
									slidesToScroll: 1,
									arrows: false,
									autoplay: true,
									autoplaySpeed: 4000
								});
							}
						}
					}

					const listWrapperPosition = listWrapper.offsetTop - 150;
					window.scrollTo({
						top: listWrapperPosition,
						behavior: 'smooth'
					});
				})
		})
	})

	handleResize();

	function handleResize() {
		let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		const companyFeedbacks = $(".company-feedbacks");
		if(screenWidth <= 768 ) {
			if(companyFeedbacks.length) {
				if(!companyFeedbacks.hasClass("slick-initialized")) {
					companyFeedbacks.slick({
						dots: true,
						infinite: true,
						speed: 500,
						slidesToShow: 1,
						slidesToScroll: 1,
						arrows: false,
						autoplay: true,
						autoplaySpeed: 4000
					});
				}
			}
		} else {
			if(companyFeedbacks.length && companyFeedbacks.hasClass("slick-initialized")) {
				companyFeedbacks.slick("unslick");
			}
		}
	}

	window.addEventListener('resize', handleResize);


	/**
	 * Occurs when all HTML has been fully loaded and passed by the parser,
	 * without waiting for the stylesheets, images and frames to finish loading
	 */
	document.addEventListener("DOMContentLoaded", function(event) {

		const projectSize = document.querySelector('#project-size');
		const hourlyRate = document.querySelector('#hourly-rate');
		const totalEmployees = document.querySelector('#total-employees');
		const totalBudget = document.querySelector('#total-budget');
		const add_file = document.querySelector('#mfcf7_zl_add_file');

		if(totalBudget) {
			new rSlider({
				target: '#total-budget',
				scale: true,
				labels: true,
				tooltip:  false,
				values: ['€1000', '€10.000', '€50.000', '€100.000', '€100.000+'],
				set: ['€1000'],
			});
		}

		if(totalEmployees) {
			new rSlider({
				target: '#total-employees',
				scale: true,
				labels: true,
				tooltip:  false,
				values: ['1', '2-9', '10-49', '50-249', '1000+'],
				set: ['1'],
			});
		}

		if(hourlyRate) {
			new rSlider({
				target: '#hourly-rate',
				scale: true,
				labels: true,
				tooltip:  false,
				values: ['€25<', '€25 - €49', '€50 - €99', '€100 - €149', '€150+'],
				set: ['€25<'],
			});
		}

		if(projectSize) {
			const projectSize = new rSlider({
				target: '#project-size',
				scale: true,
				labels: true,
				tooltip:  false,
				values: ['€1000<', '€1000+', '€5000+', '€25000+', '€50000+'],
				set: ['€1000<'],
			});
		}

		$(".tokenizer-services").select2({
			tags: true,
			placeholder: "",
			tokenSeparators: [',', ' '],
			minimumResultsForSearch: -1,
			dropdownParent: $(".tokenizer-services").parent(),
		}).on("select2:selecting", function(e) {

			const selectedTokens = $(this).val() ? $(this).val().length : 0;

			if (selectedTokens >= 10) {
				e.preventDefault();
			}

		}).on("select2:opening", function(e) {

			const selectedTokens = $(this).val() ? $(this).val().length : 0;

			if (selectedTokens >= 10) {
				e.preventDefault();

				$(this).siblings('.select2-container').find('.select2-search__field').prop('disabled', true);
			} else {

				$(this).siblings('.select2-container').find('.select2-search__field').prop('disabled', false);
			}
		}).on("select2:typing", function(e) {

			const selectedTokens = $(this).val() ? $(this).val().length : 0;

			if (selectedTokens >= 10) {
				e.preventDefault();
			}
		});

		$(".tokenizer-industries").select2({
			tags: true,
			placeholder: "",
			tokenSeparators: [',', ' '],
			minimumResultsForSearch: -1,
			dropdownParent: $(".tokenizer-industries").parent(),
		}).on("select2:selecting", function(e) {

			const selectedTokens = $(this).val() ? $(this).val().length : 0;

			if (selectedTokens >= 10) {
				e.preventDefault();
			}
		}).on("select2:opening", function(e) {

			const selectedTokens = $(this).val() ? $(this).val().length : 0;

			if (selectedTokens >= 10) {
				e.preventDefault();

				$(this).siblings('.select2-container').find('.select2-search__field').prop('disabled', true);
			} else {

				$(this).siblings('.select2-container').find('.select2-search__field').prop('disabled', false);
			}
		}).on("select2:typing", function(e) {

			const selectedTokens = $(this).val() ? $(this).val().length : 0;

			if (selectedTokens >= 10) {
				e.preventDefault();
			}
		});



		// Init Closest polyfill method ( Don't delete it )
		closest_polyfill();

		// Init Popup Windows ( use it if you need Popup functionality )
		//const popup_instance = new Popup();
		//popup_instance.init();


		/**
		 * Add global handler for click event
		 * The main idea - to improve site performance
		 * We added only 1 event handler to "click" event
		 * and then use date-role attribute on each( needed ) elements
		 * to define on which element event was executed..
		 */
		document.body.addEventListener( 'click', event => {
			const ROLE = event.target.dataset.role;
			const clickElem = event.target;

			if ( !ROLE ) return;

			switch ( ROLE ) {
				case 'test-date':
				{

				}
					break;
			}
		} );

	});

	// Use it when you need to know that everything is loaded (html, styles, images)
	window.addEventListener('load', (event) => {

	});

})( jQuery );

